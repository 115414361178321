<template>
  <nav class="top-nav" id="idTopNav">
    <a href="/" title="Calculator" class="active">calculatorspace.com</a>

    <div class="dropdown">
      <button class="dropbtn" v-on:click="menuClick('idFinance')">Finance
        <i class="fa fa-caret-down"></i>
      </button>
      <div class="dropdown-content" id="idFinance">
        <a href="/compound-interest" title="compound interest calculator">Compound Interest Calculator</a>
        <a href="/currency" title="currency converter">Currency Converter</a>
        <a href="/discount" title="discount calculator">Discount Calculator</a>
        <a href="/iban" title="IBAN validator">IBAN Validator</a>
        <a href="/markup" title="markup calculator">Markup Calculator</a>
        <a href="/mortgage" title="mortgage calculator">Mortgage Calculator</a>
        <a href="/profit" title="profit calculator">Profit Calculator</a>
        <a href="/salary-to-hourly" title="salary to hourly wage calculator">Salary to Hourly Calculator</a>
        <a href="/tax" title="tax calculator">Tax Calculator</a>
      </div>
    </div>

    <div class="dropdown">
      <button class="dropbtn" v-on:click="menuClick('idHealth')">Health
        <i class="fa fa-caret-down"></i>
      </button>
      <div class="dropdown-content" id="idHealth">
        <a href="/bmi" title="calculate body mass index">BMI Calculator</a>
        <a href="/regl" title="regl calculator">Regl Calculator</a>
      </div>
    </div>

    <div class="dropdown">
      <button class="dropbtn" v-on:click="menuClick('idMathematics')">Mathematics
        <i class="fa fa-caret-down"></i>
      </button>
      <div class="dropdown-content" id="idMathematics">
        <a href="/average" title="average calculator">Average Calculator</a>
        <a href="/circle" title="circle calculator">Circle Calculator</a>
        <a href="/cube" title="cube calculator">Cube Calculator</a>
        <a href="/exponent" title="exponent calculator">Exponent Calculator</a>
        <a href="/fractions" title="fractions calculator">Fractions Calculator</a>
        <a href="/group-work" title="group work calculator">Group Work Calculator</a>
        <a href="/logarithm" title="logarithm converter">Logarithm Converter</a>
        <a href="/luhn" title="luhn algorithm validator">Luhn Algorithm Validator</a>
        <a href="/percentage" title="percentage calculator">Percentage Calculator</a>
        <a href="/pixels-to-inches" title="pixels to inches converter">Pixels to Inches Converter</a>
        <a href="/radian" title="radian calculator">Radian Calculator</a>
        <a href="/roman-numerals" title="roman numerals converter">Roman Numerals Converter</a>
        <a href="/square-root" title="square root calculator">Square Root Calculator</a>
        <a href="/statistics" title="statistics calculator">Statistics Calculator</a>
        <a href="/tangent" title="tangent calculator">Tangent Calculator</a>
        <a href="/variance" title="variance calculator">Variance Calculator</a>
      </div>
    </div>

    <div class="dropdown">
      <button class="dropbtn" v-on:click="menuClick('idPhysics')">Physics
        <i class="fa fa-caret-down"></i>
      </button>
      <div class="dropdown-content" id="idPhysics">
        <a href="/density" title="density calculator">Density Calculator</a>
        <a href="/earthquake" title="earthquake comparator">Earthquake Comparator</a>
        <a href="/force" title="force calculator">Force Calculator</a>
        <a href="/ohm" title="Ohm's law calculator">Ohm's Law Calculator</a>
        <a href="/temperature" title="temperature converter">Temperature Converter</a>
      </div>
    </div>

    <div class="dropdown">
      <button class="dropbtn" v-on:click="menuClick('idTime')">Time
        <i class="fa fa-caret-down"></i>
      </button>
      <div class="dropdown-content" id="idTime">
        <a href="/age" title="age calculator">Age Calculator</a>
        <a href="/hours" title="hours calculator">Hours Calculator</a>
        <a href="/time-zone-difference" title="time zone difference">Time Zone Difference</a>
      </div>
    </div>

    <div class="dropdown">
      <button class="dropbtn" v-on:click="menuClick('idOther')">Other
        <i class="fa fa-caret-down"></i>
      </button>
      <div class="dropdown-content" id="idOther">
        <a href="/fuel-cost" title="fuel cost calculator">Fuel Cost Calculator</a>
        <a href="/tip" title="tip calculator">Tip Calculator</a>
        <a href="/word-counter" title="word counter">Word Counter</a>
      </div>
    </div>

    <a href="javascript:void(0);" style="font-size:15px;" class="icon" v-on:click="hamburgerMenuClick()">&#9776;</a>
  </nav>
</template>

<script>
export default {
  name: "TopNav",
  methods: {
    hamburgerMenuClick: function () {
      var x = document.getElementById("idTopNav");
      if (x.className === "top-nav") {
        x.className += " responsive";
      } else {
        x.className = "top-nav";

        var y = document.getElementById("idHealth");
        y.className = "dropdown-content";

        var z = document.getElementById("idMathematics");
        z.className = "dropdown-content";

        var p = document.getElementById("idPhysics");
        p.className = "dropdown-content";

        var k = document.getElementById("idTime");
        k.className = "dropdown-content";

        var f = document.getElementById("idFinance");
        f.className = "dropdown-content";

        var o = document.getElementById("idOther");
        o.className = "dropdown-content";
      }
    },
    /**
     *
     * @param {string} selectedId
     */
    menuClick: function (selectedId) {
      var x = document.getElementById(selectedId);
      if (x.className === "dropdown-content") {
        x.className += " aaaa";
      } else {
        x.className = "dropdown-content";
      }
    }
  }
}
</script>

<style scoped>
.top-nav {
  overflow: hidden;
  background-color: #333;
  font-family: Arial !important;
  font: 1em/1em Arial;
}

.top-nav a {
  float: left;
  display: block;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}

.active {
  background-color: #0063dc;
  color: white;
}

.top-nav .icon {
  display: none;
}

.dropdown {
  float: left;
  overflow: hidden;
}

.dropdown .dropbtn {
  font-size: 17px;
  border: none;
  outline: none;
  color: white;
  padding: 14px 16px;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.top-nav a:hover, .dropdown:hover .dropbtn {
  background-color: #555;
  color: white;
}

.dropdown-content a:hover {
  background-color: #ddd;
  color: black;
}

@media only screen and (min-width: 650px) {
  .dropdown:hover .dropdown-content {
    display: block;
  }
}

.aaaa {
  display: block;
}

@media screen and (max-width: 740px) {
  .top-nav {
    margin-left: -10px;
    margin-right: -10px;
  }

  .top-nav a:not(:first-child), .dropdown .dropbtn {
    display: none;
  }

  .top-nav a.icon {
    float: right;
    display: block;
  }
}

@media screen and (max-width: 740px) {
  .top-nav.responsive {
    position: relative;
  }

  .top-nav.responsive .icon {
    position: absolute;
    right: 0;
    top: 0;
  }

  .top-nav.responsive a {
    float: none;
    display: block;
    text-align: left;
  }

  .top-nav.responsive .dropdown {
    float: none;
  }

  .top-nav.responsive .dropdown-content {
    position: relative;
  }

  .top-nav.responsive .dropdown .dropbtn {
    display: block;
    width: 100%;
    text-align: left;
  }
}
</style>